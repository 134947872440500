export default[
  {
    id:4,
    title:'윈도우 기반 제어 S/W 및 Machine Vision',
    visible:false,
  },
  {
    id:1,
    title:'FA System',
    visible:false,
  },
  {
    id:2,
    title:'데이터베이스',
    visible:false,
  },
  {
    id:3,
    title:'Interface',
    visible:false,
  },
  {
    id:0,
    title:'PLC System',
    visible:false,
  },
  {
    id:5,
    title:'TOOL',
    visible:false,
  },
]