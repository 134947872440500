import application_detail from '../data/application_detail';
import application from '../data/application';
import styled from 'styled-components';
import React, {useEffect, useRef, useState} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from 'jquery';
// images
import app_image_1 from '../images/app_image_1.png';
import app_image_2 from '../images/app_image_2.png';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

const menuHeight = 40;
export default function() {
  let [app, setApp] = useState([application_detail[25], application_detail[26], application_detail[27], application_detail[28], application_detail[29], application_detail[30]]);
  let [ca, setCA] = useState(application[0]); // 현재 선택된 메뉴
  
  let [dropdownView, setDropdownView] = useState(false);

  let appDropdownRef = useRef();
  let dropdownMenusRef = useRef();

  const selectedAppId = (a) => {
    let newList = [];
    for(let ad of application_detail) {
      if (ad.group === a.id) {
        newList.push(ad);
      }
    }
    setApp(newList);
    setCA(a)
    $('.an').removeClass("active");
    $("#"+a.id).addClass("active");
  };

  useEffect(() => {
    appDropdownRef.current.style.transition = "all 0.5s ease-in-out";
    dropdownMenusRef.current.style.transition = "all 0.5s ease-in-out";
    if(dropdownView==false) {
      dropdownMenusRef.current.style.transform = `translateY(-${(application.length*menuHeight)}px)`;
      appDropdownRef.current.style.height = '0px';
    }
    else {
      dropdownMenusRef.current.style.transform = `translateY(0vw)`;
      appDropdownRef.current.style.height = (application.length*menuHeight)+'px';
    }
  }, [dropdownView]);

  return(
    <>
      <div className='d-flex desktop-tab-view container justify-content-center'>
        <ImageLine>
          <div className="w-75 h-100 m-auto web-bgcolor-5-hex position-relative">
            <Image
              width="80%"
              paddingTop="90%"
              backgroundImage={app_image_1}
              top="10%"
              left="30%"
            />
            <Image
              width="70%"
              paddingTop="50%"
              backgroundImage={app_image_2}
              top="50%"
              left="-15%"
            />
          </div>
          
        </ImageLine>
        <AppCaption>
          <h4 className='text-height-1-5 mb-5 controller-title'>다양한 언어와 제어기</h4>
            {
              application.map((a, i) => {
                return(
                  <>
                  <ButtonToggle className={'web-color-white app-color-hex-5'} key={a.title}>{a.title}</ButtonToggle>
                  <div className='justify-content-left w-20 app-caption-box'>
                    <Detail>
                      <div className='w-100'>
                      {
                        application_detail.map((ad) => {
                          if(a.id == ad.group) {
                            return(
                              <span className='' key={ad.name}>{ad.name} | </span>
                            )
                          }
                        })
                      }
                      </div>
                    </Detail>
                  </div>
                  </>
                )
              })
            }
          </AppCaption>
      </div>

      <div className='web-bgcolor-5-hex sm-view'>
        <div className='container '>
          <div className='justify-content-center'>
            <h5 className='fw-bold mb-3'>
              아이코드랩은 다양한 언어와 제어기를 기반으로 개발을 진행합니다.
            </h5>
            <div>

              {/* 작은 화면 */}
              <div className="sm-view mb-3">
                <div 
                onClick={() => {dropdownView?setDropdownView(false):setDropdownView(true)}} 
                className="d-flex justify-content-between align-items-center web-bgcolor-1-hex web-color-white py-2 px-3 cursor-pointer"
                >
                  <div>{ca.title}</div>
                  <FontAwesomeIcon icon = {dropdownView?faCaretUp:faCaretDown} size="1x" color='white'/>
                </div>
                
                
                <div className="app-dropdown" ref={appDropdownRef}>
                  <div className="w-100 h-100" ref={dropdownMenusRef}>
                    {application.map((a) => {
                      return(
                        <>
                        <DropdownMenus 
                        className="px-3 py-2" 
                        onClick={() => {selectedAppId(a)}}
                        key={a.title}
                        >
                          {a.title}
                        </DropdownMenus>
                        <hr className='m-0'/>
                        </>
                      )
                    })}</div>
                  </div>

              </div>

              <div className='p-3 web-bgcolor-white'>
                {app.map((ad) => {
                return (<div className='text-center text-height-2' key={ad.name}>{ad.name}</div>)
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

    </>  
  )
}


// let DetailContainer = styled.span`
// position:relative;
// width:20%;
// height:300px;
// padding:10px;
// `

// let Detail = styled.div`
// width:100%;
// height:100%;
// line-height: 22px;
// border:2px solid white;
// padding-inline:10px;
// font-size:12px;
// text-align:center;
// `
let DropdownMenus = styled.div`
  width:100%;
  height:${menuHeight}px;
  cursor:pointer;
  transition:ease-in-out 0.3s;
`

let ButtonToggle = styled.div`
margin-top: 30px;
text-align: center;
z-index: 1;
padding:5px;
width:200px;
border-radius: 5px;
`

let Detail = styled.div`
width:100%;
height:auto;
line-height: 2em;
border:2px solid white;
font-size:0.7em;
text-align:left;
`
let ImageLine = styled.div`
position:relative;
flex:1 1 50%;
padding-inline:auto;
height:auto;
`
let Image = styled.div`
position:absolute;
top:${(props) => props.top};
left:${(props) => props.left};
width:${(props) => props.width};
padding-top:${(props) => props.paddingTop};
background-image: url('${(props) => props.backgroundImage}');
background-repeat:none;
background-size:100%;
border-color:white;
border-style:solid;
border-width:10px;
`
let AppCaption = styled.div`
flex:1 1 50%;
padding-left:100px;
`
// let ImageLine = styled.div`
// position:relative;
// height:200px;
// background-image: url('${testalize}');
// background-repeat:none;
// background-size:100%;
// `