export default[
    {
        id:0,
        name:'PC제어', 
    },
    {
        id:1,
        name:'CIM(SECS/GEM)', 
    },
    {
        id:6,
        name:'Machine Vision', 
    },
    {
        id:2,
        name:'RTMS', 
    },
    {
        id:3,
        name:'PLC 제어', 
    },
    {
        id:4,
        name:'생산관리시스템', 
    },
    {
        id:5,
        name:'Firmware', 
    },
]