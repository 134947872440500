import styled from 'styled-components';
import history from '../data/history';

export default function() {
  let p_year = "2000";

  return (
    <div className='mb-3 web-color-8-hex'>
      {history.map((h) => {
        let year_text = "";
        if(p_year != h.year) {
          p_year = year_text = h.year;
        }
        return(
          <div className="history-group" key={h.maintitle}>
            <div className="history-month">
              <div className="z-index-1 w-auto border-bottom-black-2">
                <div className="header_year">{year_text}</div>
              </div>
              <div className="detail-year h-auto border-bottom-black-2">
                <div className="header_month">{h.month}</div>
              </div>
            </div>
            <div className='history-content pb-0'>    
              <div className="w-100 text-left pt-1">
                <div width='auto' className='mb-3 history-mt'>{h.maintitle} <span className='mx-2 web-color-1-hex history-st'>{h.subtitle}</span></div>
                
                <P>{h.detail}</P>
                {h.image!=""?
                <img src={h.image} alt={h.maintitle} className="w-100"/>:
                <></>}
                
              </div>
            </div>
          </div>
        )
      }) }
    </div>
  )
}

const P = styled.div`
  line-height:30px;
  white-space: pre-wrap;
  margin-bottom:30px;
`