
import PCControl from '../images/PCControl.png';
import PCControl_Large from '../images/PCControl_Large.png';
import SEMI from '../images/SEMI.png';
import SEMI_Large from '../images/SEMI_Large.png';
import iems from '../images/iems.png';
import iems_Large from '../images/iems_large.png';
import RTMS from '../images/RTMS.png';
import RTMS_Large from '../images/RTMS_large.png';
import PLCSystem from '../images/PLCSystem.png';
import PLCSystem_large from '../images/PLCSystem_large.png';
import PLCSystem2 from '../images/PLCSystem2.png';
import PLCSystem_large2 from '../images/PLCSystem_large2.png';
import POP from '../images/POP.png';
import POP_Large from '../images/POP_Large.png';
import Firmware from '../images/Firmware.png';
import Firmware_Large from '../images/Firmware_large.png';
import Machine_Vision from '../images/machinevision.png';
export default[
    {
        id:0,
        type:0,
        title:'PC제어', 
        thumbnail_image:PCControl,
        image:PCControl_Large,
        contents:'Windows 응용프로그램을 통한 자동화 설비 컨트롤의 실현으로 다양한 제조사의 모터 및 액츄에이터에 맞는 최적의 제어 솔루션을 제공합니다.',
        icon_text:'WINDOWS / 설비제어',
    },
    {
        id:1,
        type:1,
        title:'CIM(SECS/GEM)', 
        thumbnail_image:SEMI,
        image:SEMI_Large,
        contents:'제조 현장의 장비와 호스트 시스템 간의 통신 인터페이스를 제공합니다. 다양한 범용 통신 프로토콜과 달리 호스트 시스템과 표준적이고 일관된 프로토콜을 사용하여 통신 할 수 있고, SECS I/SECS II/GEM 통신을 포함합니다.',
        icon_text:'SECS / GEM',
    },
    {
        id:8,
        type:1,
        title:'CIM(SECS/GEM)', 
        thumbnail_image:iems,
        image:iems_Large,
        contents:'일일, 주간, 월간 단위의 생산 가동 데이터 및 공장, 제품, 라인, 설비별 생산능력을 분석합니다. 공정능력, 생산시간, 포준손실율 등의 생산정보를 DB분석합니다. 제폼 생산량 및 재고를 예측 분석합니다.',
        icon_text:'SECS / GEM',
    },
    {
        id:7,
        type:6,
        title:'Align Vision S/W', 
        thumbnail_image:Machine_Vision,
        image:Machine_Vision,
        contents:'linear stage control/분산제어 시스템 개발/vision align algorithm/Sequence Recipe 및 Flow Recipe Management 등을 제공합니다.',
        icon_text:'Align Vision S/W',
    },
    {
        id:2,
        type:2,
        title:'RealTime Monitor System', 
        thumbnail_image:RTMS,
        image:RTMS_Large,
        contents:'장비의 상태 모니터링 솔루션은 실시간 설비의 상태를 원격지에서 모니터링하고 다운타임을 예측하며, 유지 보수 비용을 절감하며, 운영 안정성을 개선하여 생산량을 향상시킵니다.',
        icon_text:'REMOTE MONITORING',
    },
    {
        id:3,
        type:3,
        title:'미쯔비시 PLC 제어', 
        thumbnail_image:PLCSystem,
        image:PLCSystem_large,
        contents:'PLC는 입력 프로그램에 의해 순차적 논리 처리와 그 출력 결과를 이용해 연결된 외부장치를 제어하기 위한 대표적 처리장치로 반도체/디스플레이/2차전지/자동차 분야에서 최적의 제어 시스템을 제공합니다.',
        icon_text:'PLC / HMI',
    },
    {
        id:4,
        type:3,
        title:'지멘스 PLC 제어', 
        thumbnail_image:PLCSystem2,
        image:PLCSystem_large2,
        contents:'PLC는 입력 프로그램에 의해 순차적 논리 처리와 그 출력 결과를 이용해 연결된 외부장치를 제어하기 위한 대표적 처리장치로 반도체/디스플레이/2차전지/자동차 분야에서 최적의 제어 시스템을 제공합니다.',
        icon_text:'PLC / HMI',
    },
    {
        id:5,
        type:4,
        title:'POP(생산시점관리시스템)', 
        thumbnail_image:POP,
        image:POP_Large,
        contents:"중.소 제조 업체의 생산활동이 행해 질 때 발생하는 정보를 시점에 맞게 획득하고, 관리상 필요한 각종의 정보를 가능하여 제공하는 '생산시점정보관리시스템'을 개발하고 공급합니다.",
        icon_text:'제조 생산관리 시스템',
    },
    {
        id:6,
        type:5,
        title:'Firmware S/W', 
        thumbnail_image:Firmware,
        image:Firmware_Large,
        contents:'ATmega/AVR/ARDUINO/라즈베리파이 등의 MCU Firmware를 개발 및 서비스를 제공합니다.',
        icon_text:'ATMEGA / AVR / 라떼판다',
    },
]