import styled from 'styled-components';
import React, {useRef} from 'react';
import emailjs from '@emailjs/browser';

//images
import defaultprofile from '../images/defaultprofile.png';
import momos from '../images/momos.jpg';
import ChosunIn from '../images/ChosunIn.jpeg';


export default function() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_oirhnr4', 'template_jl9gvjh', form.current, 'hTNgX_AJoTK4o9z_J')
      .then((result)=>{
        console.log(result.text);
        alert("메일을 성공적으로 보냈습니다.");
      }, (error) => {
        console.log(error.text);
      });
  };

  return(
    <div className='mi justify-content-between'>
      <div className="justify-content-center d-flex w-100 contact-card-container">

        <div className='web-bgcolor-5-hex contact-card'>
          <ProfileImg src={momos} alt="MOMO"></ProfileImg>
          <div className='contact-card-caption'>
            <div className='mt-3 font-weight-bold'>"MOMO - 개발담당”</div>
            jmseong@aicode-lab.com
          </div>
        </div>

        <div className='web-bgcolor-5-hex contact-card'>
          <ProfileImg src={ChosunIn} alt="GENIUS"></ProfileImg>
          <div className='contact-card-caption'>
          <div className='mt-3 font-weight-bold'>"GENIUS - 경영/기술영업”</div>
          sjkim@aicode-lab.com
          </div>
        </div>
      
        <div className='web-bgcolor-5-hex contact-card'>
          <ProfileImg alt="HANYI"></ProfileImg>
          <div className='contact-card-caption'>
          <div className='mt-3 font-weight-bold'>"HANYI - SW 개발”</div>
          hykim@aicode-lab.com
          </div>
        </div>

      </div>
      <form ref={form} onSubmit={sendEmail} className='w-100'>
        <div className="d-flex mi">
            <input type="text" name="from_name" title="Name" placeholder="Enter your name" className="input-box"/>
          
            <input type="text" name="from_email" title="Email" placeholder="Enter your e-mail" className="input-box"/>
        
        </div>
        <div>
            <input type="text" name="from_phone" title="Phone Number" placeholder="Enter your phone number" className="input-box"/></div>
        <div>
        <textarea id="comments" placeholder='Message' className="input-box" name="message"></textarea>
        </div>
        <div>
          <input type="submit" value="메일 보내기" className="button"/>
        </div>
      </form>
    </div>
  )
}

let ProfileImg = styled.img`
width:70px;
height:70px;
border-radius:70%;
margin-top:-20px;
`
ProfileImg.defaultProps ={
src:defaultprofile,
};