/* global kakao */
import React, {useEffect} from "react";
import styled from 'styled-components';

//images
import icon from '../images/marker.png';

const {kakao} = window;

export default function() {
  useEffect(() => {
    let container = document.getElementById("map");

    let options ={
      center:new window.kakao.maps.LatLng(36.736240, 127.077353 ),
      level:4,
    };

    let map = new window.kakao.maps.Map(container, options);
    // map.setDraggable(false);
    // map.setZoomable(false);

    // // 마커가 표시될 위치입니다 
    // var markerPosition  = new kakao.maps.LatLng(36.735589, 127.077404); 

    // // 마커를 생성합니다
    // var marker = new kakao.maps.Marker({
    //     position: markerPosition
    // });

    // // 마커가 지도 위에 표시되도록 설정합니다
    // marker.setMap(map);

    var imageSrc = icon, // 마커이미지의 주소입니다    
    imageSize = new kakao.maps.Size(48, 56), // 마커이미지의 크기입니다
    imageOption = {offset: new kakao.maps.Point(27, 50)}; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
      
    // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
    var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption),
        markerPosition = new kakao.maps.LatLng(36.735589, 127.077404); // 마커가 표시될 위치입니다

    // 마커를 생성합니다
    var marker = new kakao.maps.Marker({
        position: markerPosition, 
        image: markerImage // 마커이미지 설정 
    });
    // 마커가 지도 위에 표시되도록 설정합니다
    marker.setMap(map);
    console.log("loading kakaomap");

    // 확대, 축소 컨트롤 올리기
    var zoomControl = new kakao.maps.ZoomControl();
    map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);
  }, []);

  return(
    <MapTest id='map'></MapTest>
  )
}

let MapTest = styled.div`
width:100%;
height:220px;
background:black;
`
