export default [
  {
    group:0,
    name:'Mitsubishi PLC'
  },
  {
    group:0,
    name:'Mitsubishi C언어 PLC'
  },
  {
    group:0,
    name:'SIEMENS PLC (Ladder/STL)'
  },

  {
    group:1,
    name:'Motion Control'
  },
  {
    group:1,
    name:'DIO'
  },
  {
    group:1,
    name:'ADC'
  },
  {
    group:1,
    name:'DAC'
  },
  {
    group:1,
    name:'CC-Link'
  },
  {
    group:1,
    name:'Device-Net'
  },
  {
    group:1,
    name:'Save-Net'
  },
  {
    group:1,
    name:'EtherCAT'
  },
  {
    group:1,
    name:'EthernetIP'
  },
  {
    group:1,
    name:'RS485'
  },

  {
    group:2,
    name:'MS-SQL'
  },
  {
    group:2,
    name:'MySQL'
  },
  {
    group:2,
    name:'PostgreSQL'
  },
  {
    group:2,
    name:'SQLite'
  },
  {
    group:2,
    name:'Access 등 SQL을 이용한 쿼리 및 튜닝'
  },

  {
    group:3,
    name:'Serial'
  },
  {
    group:3,
    name:'Socket(TCP, UDP)'
  },
  {
    group:3,
    name:'FTP'
  },
  {
    group:3,
    name:'Http Web Request'
  },
  {
    group:3,
    name:'SECS-I'
  },
  {
    group:3,
    name:'SECS-II'
  },
  {
    group:3,
    name:'Windows Foundation communication'
  },
  
  {
    group:4,
    name:'Visual C++'
  },
  {
    group:4,
    name:'Visual C#'
  },
  {
    group:4,
    name:'Visual Basic'
  },
  {
    group:4,
    name:'Managed C++'
  },
  {
    group:4,
    name:'Python'
  },
  {
    group:4,
    name:'XCode'
  },
  
  {
    group:5,
    name:'Visual Studio'
  },
  {
    group:5,
    name:'Visual Studio Code'
  },
  {
    group:5,
    name:'Work-Bench'
  },
  {
    group:5,
    name:'SVN'
  },
  {
    group:5,
    name:'Git'
  },
]