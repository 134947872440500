import './App.css';
import {Navbar, navbar} from 'react-bootstrap';
import {Container, container} from 'react-bootstrap';
import {Nav, nav} from 'react-bootstrap';
import styled from 'styled-components';
import $ from 'jquery';
import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// image
import defaultImage from './images/defaultimage.png';
import logo from './images/logo.png';

// data
// component
import Slider from './components/Slider';
import Application from './components/Application';
import Project from './components/Project';
import History from './components/History';
import Contact from './components/Contact';
import Map from './components/Map';
import { faBriefcase, faEarth, faFaceLaughBeam, faMobileButton, faHome, faMemory, faPhone, faUsers } from '@fortawesome/free-solid-svg-icons';

function App() {
  
  let Paragraph = styled.div`
    line-height: 30px;
    text-align:left;
  `

  // 상단바 메뉴
  const menuBar=['AICODELAB', 'APPLICATION', 'PROJECTS', 'HISTORY', 'CONTACT'];

  
  // 화면 스크롤 시 상단 메뉴 글자색 변경
  let scrollId = '#AICODELAB';
  function setScrollId(id) {
    scrollId = id;
  }

  let _bTop = true; 
  $(window).on('scroll', function() {

    if($(this).scrollTop() == 0) {
      $(".navbar").removeClass("hide");
      _bTop = true;
    }
    else if(_bTop==true){
      $(".navbar").addClass("hide");
      _bTop = false;
    }
    
    menuBar.forEach(menu => {
      if(checkVisible($('#' + menu))) {
        setScrollId('#' + menu);
        $('.nav-link').removeClass('underbar-active');
        $('#' + menu + '_ub').addClass('underbar-active');
      } else {
        return false;
      }
    });

  })
  
  function checkVisible(elm, d) {
    let vh = $(window).height(),  // 뷰포트 세로길이
      st = $(window).scrollTop(), // 웹페이지에서 뷰포트의 위치
      y = $(elm).offset().top;  // 웹페이지에서 elm의 위치
    return (y) < (st + vh/2);
  }

  function pageScroll(menu) {
    let y = $("#" + menu).offset().top;  // 웹페이지에서 elm의 위치
    window.scrollTo(0,y);
  }
  
  // reload시 페이지 최상단으로 올리기
  useEffect(() => {
    window.onload = function() {
      setTimeout(function() {
        window.scrollTo(0,0);
        _bTop = true; 
      $('#AICODELAB_ub').addClass('underbar-active');}
      ,100);
    };
  }, []);

  const handleImgError = (e) => {
    e.target.src = defaultImage;
  }


  return (
    <div className="App">
      <header>
        <Navbar fixed='top' collapseOnSelect expand="md" bg="white" variant="light">
          <Container className="header">
            <Navbar.Brand onClick={()=>{pageScroll("AICODELAB")}}><img src={logo} className='navbar-nav'/></Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
               <Nav className="ms-auto top-nav">
                 {menuBar.map((menu) => {
                   return(
                    <Nav.Link id={menu + '_ub'} onClick={() => {pageScroll(menu)}} key={menu}>{menu}<div className="underbar"/></Nav.Link>
                   )
                 })}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>


      <main className="App-main" id="AICODELAB">
        <section >
          <Slider/>
        </section>
        <section className="container">
          <Paragraph className="mt-4 text-start">AICODE-LAB pursues a global technology leader who always thinks of human beings and society, with applied intelligence solutions.<br/>
  아이코드랩은 응용 지식기술(applied intelligence solutions) 을 통해 사람과 사회를 생각하는 글로벌 기술선도기업을 추구합니다.
          </Paragraph>

          <Paragraph className="mt-5">아이코드랩은 통합 제어 소프트웨어 및 생산 자동화 시스템 개발 분야에서 최상의 IT 서비스를 제공하고 있습니다. 최상의 기술, 한발 앞선 개발, 안정적인 서비스를 바탕으로 훌륭한 비지니스 파트너가 되어드립니다.
          </Paragraph>
          <div className='image-line mt-5'>
            <div className='py-5 grad-white-trans'>
              <h5><b>아이코드랩이 지켜 나갈 약속</b></h5>
              <Paragraph className="mt-4">
                We promise to make the sustainable growth together with customer real, by demonstrating our flexible thinking and technological adaptability to lead ‘INDUSTRY 4.0’.
              </Paragraph>
              <Paragraph>
                우리는 "INDUSTRY 4.0" 을 선도하기 위한 유연한 사고(Flexibility of Thinking)와 기술순응력(Technology Adaptability)을 통해 고객과 함께 지속가능한 성장을 실현하겠습니다.
              </Paragraph>
            </div>
          </div>
        </section>
        <div className="web-bgcolor-5-hex">
          <div className="container">
            <h4 className="pt-1">DIRECTION</h4> 
            <div className="pt-4 d-flex direction">
              <div className="direction-content justify-content-center">
                <Table>
                  <tbody>
                    <tr>
                      <TableR1>
                        <FontAwesomeIcon icon = {faMemory} size="5x" className='text-color-hex-0'/>
                      </TableR1>
                      <TableR1>
                        <FontAwesomeIcon icon = {faUsers} size="5x" className='text-color-hex-2'/>
                      </TableR1>
                      <TableR1>
                        <FontAwesomeIcon icon = {faFaceLaughBeam} size="5x" className='text-color-hex-4'/>
                      </TableR1>
                    </tr>
                    <tr>
                      <TableR2>
                  <h5 className="mt-3 font-weight-bold">기술혁신</h5>
                      </TableR2>
                      <TableR2>
                  <h5 className="mt-3 font-weight-bold">인재육성</h5>
                      </TableR2>
                      <TableR2>
                  <h5 className="mt-3 font-weight-bold">가치창출</h5>
                      </TableR2>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="text-left direction-content">
                <Paragraph>
                Focusing on the three main directions, ‘Technology Innovation, Human Resource Development, Value Creation’, we ultimately aim to realize customer value through the highest quality solutions and services based on competent human resources and technology.
                </Paragraph>
                <Paragraph>
                ‘기술혁신, 인재육성, 가치창출’을 경영의 지향점으로 삼고, 인재와 기술을 바탕으로 최고의 솔루션과 서비스 제공을 통해 고객 가치 실현을 궁극적 목표로 삼고 있습니다. 
                </Paragraph>
              </div>
            </div>
          </div>
        </div>
   


        <ContainerTitle enTitle="APPLICATION" koTitle="사업분야"/>
        <section className='align-left'>
          <Application/>
        </section>




        <ContainerTitle enTitle="PROJECTS" koTitle="프로젝트"/>
        <section className="container">
          <Project/>
        </section>

        


        <ContainerTitle enTitle="HISTORY" koTitle="연혁"/>
        <section className="container">
          <History/>
        </section>
        





        




        <section className="container">
          <ContainerTitle enTitle="CONTACT" koTitle=""/>
          <Contact/>
        </section>



      </main>



      <footer className="App-footer">
        <div className="web-bgcolor-5-hex">
          <div className="container mi">
            <ErrorBoundary width="50%" height="100%">
              {/* 카카오맵 지도 api */}
              <Map/>
            </ErrorBoundary>
            <div className="footer-address">
              <table className="footer-info">
                <tr>
                  <td className="vertical-align-top px-4">
                  <FontAwesomeIcon icon = {faHome} size="1x" className='web-color-3-hex'/>
                  </td>
                  <td className="text-left">
                  충청남도 아산시 배방읍 호서로 79번길 20 201호<br/>
                  (호서대 벤처창조융합관)
                  </td>
                </tr>
                <tr>
                  <td className="vertical-align-top px-4">
                  <FontAwesomeIcon icon = {faPhone} size="1x" className='web-color-3-hex'/>
                  </td>
                  <td className="text-left">(+82) 41-542-7001</td>
                </tr>
                <tr>
                  <td className="vertical-align-top px-4">
                  <FontAwesomeIcon icon = {faMobileButton} size="1x" className='web-color-3-hex'/>
                  </td>
                  <td className="text-left">010-5905-7155</td>
                </tr>
                <tr>
                  <td className="vertical-align-top px-4">
                  <FontAwesomeIcon icon = {faEarth} size="1x" className='web-color-3-hex'/>
                  </td>
                  <td className="text-left">jmseong@aicode-lab.com</td>
                </tr>
                <tr>
                  <td className="vertical-align-top px-4">
                  <FontAwesomeIcon icon = {faBriefcase} size="1x" className='web-color-3-hex'/>
                  </td>
                  <td className="text-left">09:00 - 18:00</td>
                </tr>
              </table>
            </div>

          </div>

          {/* 워터마크 */}
          <div className='web-color-2-hex'>© AICODELAB CO. ALL RIGHTS RESERVED</div>
        </div>

      </footer>
    </div>
  );
}

function ContainerTitle(props) {
  return(
    <>
    {/* <h2 id={props.enTitle} className='container container-title'> {props.koTitle} &nbsp; | &nbsp; </h2> */}
    <h3 id={props.enTitle} className='web-color-1-hex container-title'>{props.enTitle}</h3>
    </>
  )
}

const Table = styled.table`
display: table;
margin-inline:auto;
width:90%;
height:100%;
`

const TableR1 = styled.td`
vertical-align: bottom;
`

const TableR2 = styled.td`
vertical-align: top;
`

// 에러 경계 : 예외처리하고 싶은 부분에 컴포넌트처럼 사용
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError:false};
  }

  static getDerivedStateFromError(error) {
    // 다음 렌더링에서 폴백 UI가 보이도록 상태를 업데이트합니다.
    return {hasError:false};
  }

  static componentDidCatch(error, errorInfo) {
    // 에러 리포팅 서비스에 에러를 기록할 수 도 있습니다.
    //logErrorToMyService(error, errorInfo);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.    
    return { hasError: true };
  }

  render() {
    if(this.state.hasError) {
      // 폴백 UI를 커스텀하여 렌더링할 수 있습니다.
      return <ErrorDiv width={this.props.width} height={this.props.height} className="web-bgcolor-5-hex p-auto">load failure.</ErrorDiv>
    }

    return this.props.children;
  }
}

const ErrorDiv = styled.h5`
  width:${props=>props.width?props.width:"auto"};
  height:${props=>props.height?props.height:"auto"};
`
export default App;
