// images
import wet_cleaner from '../images/wet_cleaner.png';
import dip_pcb from '../images/dip_pcb.png';
import edmms from '../images/edmms.png';
import logistics_automation from '../images/logistics_automation.png';
import coating_system from '../images/coating_system.png';
import full_auto_foup_loader from '../images/full_auto_foup_loader.png';
import portable_buffer_system from '../images/portable_buffer_system.png';
import cluster_tool_control_sw from '../images/cluster_tool_control_sw.png';

export default[
  {
    year:'2022',
    month:'Jun.',
    maintitle:'i-SEMI SECS-II Communication Driver 개발',
    subtitle:'',
    detail:'',
    image:'',
  },
  {
    year:'2022',
    month:'Apr.',
    maintitle:'충남디스플레이산업기업협의회 업무협약',
    subtitle:'',
    detail:'',
    image:'',
  },
  {
    year:'2022',
    month:'Apr.',
    maintitle:'충남청년창업사관학교 12기 입교 (AI 기반 자동 코딩시스템)',
    subtitle:'',
    detail:'',
    image:'',
  },
  {
    year:'2022',
    month:'Jan.',
    maintitle:'.Net SCADA UI 플랫폼 i-GUI 개발',
    subtitle:'',
    detail:'',
    image:'',
  },
  {
    year:'2021',
    month:'Oct.',
    maintitle:'솔브레인(주) 협력사 등록',
    subtitle:'',
    detail:'',
    image:'',
  },
  {
    year:'2021',
    month:'Jul.',
    maintitle:'호서대학교 창업보육센터 입주 (벤처창조기업관/벤처창조융합관)',
    subtitle:'',
    detail:'',
    image:'',
  },
  {
    year:'2021',
    month:'Jul.',
    maintitle:'소프트웨어 개발 및 공급업 등록',
    subtitle:'',
    detail:'',
    image:'',
  },
  {
    year:'2021',
    month:'Jul.',
    maintitle:'아이코드랩 주식회사 설립',
    subtitle:'',
    detail:'',
    image:'',
  },
  // {
  //   year:'2022',
  //   month:'Feb.',
  //   maintitle:'LTV',
  //   subtitle:'(주)ETS 공장 PC제어 프로그램',
  //   detail:'(주)ETS 공장 PC제어 프로그램 LTV 개발',
  //   image:'',
  // },
  // {
  //   year:'',
  //   month:'',
  //   maintitle:'WET CLEANER',
  //   subtitle:'Glass 표면을 DIW 를 이용해 세정하기 위한 장치',
  //   detail:'- CC-Link IO, ADC, DAC 구성, SSCNET Motion 구축 or Ethercat 으로 구성\n- PID 온도 제어 및 장치 RS485 및 RS232 통신 구현\n- TCP/IP 통신 구현 (하드웨어 통신)\n- BLDC Motor 속도 제어\n- Mitsubishi Motion board - Servo 제어\n- Block Control 상위 인터페이스 구현',
  //   image:wet_cleaner,
  // },
  // {
  //   year:'',
  //   month:'',
  //   maintitle:'디스미어 도금장비',
  //   subtitle:'DIP 방식의 PCB 기판 도금 장비',
  //   detail:'- 유럽 A 사 SECS/GEM 대응\n- Tank, Pipe 및 Valve, Heater 상태 시각화\n- Multi Carrier Scheduler 개발\n- PID 온도 제어 및 장치 RS485 및 RS232 통신 구현\n- BLDC / Inverter 제어\n- EQP EDPMS 구현',
  //   image:dip_pcb,
  // },
  // {
  //   year:'',
  //   month:'',
  //   maintitle:'정전기 단선 측정 모니터링 시스템',
  //   subtitle:'',
  //   detail:'- Atmega2560 MCU Firmware 개발\n- Wheatstone Bridge 회로를 이용한 Cable 단선 검출 실현\n- Web 기반 측정기 모니터링 시스템 구축\n- S社 측정기 50EA 납품 실적',
  //   image:edmms,
  // },
  // {
  //   year:'',
  //   month:'',
  //   maintitle:'물류자동화',
  //   subtitle:'1층 물류 투입구를 통해 각 층별/장비별 제품을 공급하기 위한 장치',
  //   detail:'- 물류 설비와 연결된 공정설비 HSMS 개발 대응\n- 무선 및 고정 SCANNER 인터페이스 middlerware PC 구성\n- 물류 Conveyor 제어 및 라우팅 서버 구성\n- Web 기반으로 각 층 라인별 RealTime 제품 모니터링 제공\n- 반송 및 경로관련 Scheduling 구현.\n- PC 5EA, PLC 6EA로 구성, 스케쥴링 및 데이터베이스 개발 및 구현',
  //   image:logistics_automation,
  // },
  // {
  //   year:'',
  //   month:'',
  //   maintitle:'COATING SYSTEM ',
  //   subtitle:'Glass 표면을 얇게 코팅해 경도를 높이기 위한 장치',
  //   detail:'- CC-Link IO 및  SSCNet Motion 제어\n- RS485 / RS232 / TCP IP 프로토콜 구현\n- Anaolg Input, Output 제어\n- BLDC Motor, 미쯔비시 Servo 및 ROBOT 제어\n- My-SQL / SQLite 설비 이벤트 기록\n- 정전기 검출을 위한 Arduino 측정기 Module 구성',
  //   image:coating_system,
  // },
  // {
  //   year:'',
  //   month:'',
  //   maintitle:'FULL AUTO FOUP LOADER ',
  //   subtitle:'Foup Manual 로딩 후 OHT로 이송하기 위한 장치',
  //   detail:'- .net Win-Form Base 개발\n- CC-Link IO 및 SSCNet 모션 제어.\n- RFID 인터페이스 구현.\n- HSMS HOST Communication 구현.',
  //   image:full_auto_foup_loader,
  // },
  // {
  //   year:'',
  //   month:'',
  //   maintitle:'portable buffer system',
  //   subtitle:'제품을 Stocker 에 적재하기 위한 장비',
  //   detail:'- UR 협동 로봇 인터페이스 및 Control 프로그램 개발\n- Ethercat IO 및  Motion 제어\n- WCF -> LBS Communication 개발\n- RFID / Align Image Processing Vision 프로토콜 개발\n- 자재 적재 Point Auto Teaching 기능 개발\n- BLDC Motor 제어\n- SQLite 데이터 관리 설비 이벤트 기록',
  //   image:portable_buffer_system,
  // },
  // {
  //   year:'',
  //   month:'',
  //   maintitle:'Cluster Tool Control S/W',
  //   subtitle:'',
  //   detail:'- Cluster Module Route 스케쥴링\n- 분산제어 시스템 개발\n- Pulse Motion / Mass Flow Control\n- BLDC Motor 제어\n- Sequence Recipe 및 Flow Recipe Management\n- 성막 두께 데이터 Management\n- EDPMS Event Viewer 개발',
  //   image:cluster_tool_control_sw,
  // },
  // {
  //   year:'2021',
  //   month:'Jul.',
  //   maintitle:'아이코드랩 설립',
  //   subtitle:'',
  //   detail:'',
  //   image:'',
  // },
]