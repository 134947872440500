import testalize from '../images/testalize.jpg';
import AutoControl from '../images/autocontrolsw.png';
import macode from '../images/macode.jpg';
export default[
    {
        //icon:faPencil,
        title:'자동화 제어 S/W 개발',
        contents:'PC / PLC 기반의 반도체, 디스플레이, 2차전지 장비 제어 개발에 숙련된 엔지니어로 구성된 아이코드랩은 안정적인 시스템의 개발로 고객사의 비용과 납기 절감을 이루어 드립니다.',
        image:AutoControl,
        thumbnail_image:AutoControl,
    },
    {
        //icon:faRocket,
        title:'시스템 제어 컨설팅',
        contents:'제어기의 변경, 원가절감, 통일화 등 기존 제어시스템의 한계를 극복한 신규 개발 제어시스템의 제안을 통해 시행착오를 줄이고 안정화된 시스템을 현장에 빠르게 적용할 수 있도록 컨설팅을 진행하고 있습니다.',
        image:testalize,
        thumbnail_image:testalize,
    },
    {
        //icon:faColumns,
        title:'앱 어플리케이션 개발',
        contents:'고품질의 모바일 및 웹 어플리케이션을 빠르고 합리적인 비용에 개발해 드립니다.',
        image:macode,
        thumbnail_image:macode,
    },
]