import styled from 'styled-components';
import React, {useState, useEffect, useRef} from 'react';
import {Nav, nav} from 'react-bootstrap';
import useHover from '../hooks/useHover';

// data
import Project_type from '../data/project_type';
import Projects from '../data/projects';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
// 프로젝트 아이콘 이미지 사이즈
const icon_width= 201;
const icon_height= 136;

const Project = () => {
  
  // state 변수들
  // project section에서 선택한 nav를 저장하는 state
  let [projects, setProjects] = useState(Projects); // import한 project데이터를 state로 사용
  let [selectedProject, setSelectedProject] = useState(Projects[0]);
  let [checked, setChecked] = useState(false);
  //let [btnPrevVisible, setBtnPrevVisible] = useState(true);
  //let [btnNextVisible, setBtnNextVisible] = useState(true);
  const [currentOffset, setCurrentOffset] = useState(0);
  let [listGroupSize, setListGroupSize] = useState(0);

  const [radioValue, setRadioValue] = useState('1');
  //const {css, value } = window.popmotion; // const {} => 구조분해 할당 : object 형식을 const로 받음

  // ref
  const listGroupRef = useRef("");
  const listRef = useRef("");
  const projectDetailRef = useRef("");
  const pdGroupRef = useRef("");
  const nextBtnRef = useRef("");
  const prevBtnRef = useRef("");

  // effect
  useEffect(() => {
    listRef.current.style.transition = "all 0.5s ease-in-out";
    listRef.current.style.transform = `translateX(${currentOffset}px)`;
  }, [setMinOffset]);

  useEffect(() => {
    window.addEventListener("resize", handleResize); // 창 크기 조절할 때 불림
    return()=>{
      window.removeEventListener("resize", handleResize); // 창 크기 조절할 때 불림
    }
  }, []);

  useEffect(() => {
    projectDetailRef.current.style.transition = "all 0.5s ease-in-out";
    pdGroupRef.current.style.transition = "all 0.5s ease-in-out";
    if(checked==false) {
      projectDetailRef.current.style.transform = `translateY(-100%)`;
      pdGroupRef.current.style.height = '0px';
    }
    else {
      projectDetailRef.current.style.transform = `translateY(0vw)`;
      pdGroupRef.current.style.height = '100%';
    }
  }, [checked]);

 
  // 변수
  const thumbnailBtn_width = 221;
  const maxOffset = 0;
  let minOffset = 0;
  let subListWidth = thumbnailBtn_width*projects.length;
  // 함수
  const handleResize=()=>{
    setListGroupSize(listGroupRef.current.clientWidth)
  }
  function changeProjects(type) {
    let newList = [];
    let i=0;
    if(type == -1) {
      setProjects(Projects);
      return;
    }
    for(let p of Projects) {
      if (p.type === type) {
        newList[i++] = p;
      }
    }
    setProjects(newList);
    // 프로젝트 목록의 너비 구하기
    subListWidth = thumbnailBtn_width*projects.length;
    setCurrentOffset(0);
    setListGroupSize(listGroupRef.current.clientWidth);
  }

  function selectSubNav(project) {
    if(!checked) {
      setChecked(true);
    }
    else if(project == selectedProject) {
      setChecked(false);
    }
    
    setSelectedProject(project);
  }

  // hover 없애기
  function btnBlur (btnRef) {
    btnRef.current.style.opacity = "0.3";
    btnRef.current.style.cursor = "default";
    btnRef.current.style.pointerEvents="none";
  }
  
  function btnDistinct (btnRef) {
    btnRef.current.style.opacity = "1";
    btnRef.current.style.cursor = "pointer";
    btnRef.current.style.pointerEvents="auto";
  }

  function nextSlide () {
    setMinOffset();
    let nextOffset = currentOffset - thumbnailBtn_width;
    setCurrentOffset(nextOffset);
    if(nextOffset<=minOffset) {
      setCurrentOffset(minOffset);
      btnBlur(nextBtnRef);
    } 
    if(nextOffset<maxOffset) {
      btnDistinct(prevBtnRef);
    }
  }

  function prevSlide () {
    setMinOffset();
    let nextOffset = currentOffset+thumbnailBtn_width;
    setCurrentOffset(nextOffset);
    if(nextOffset>=maxOffset) {
      setCurrentOffset(maxOffset);
      btnBlur(prevBtnRef);
    } 
    if(nextOffset>minOffset) {
      btnDistinct(nextBtnRef);
    }
  }

  function setMinOffset(){
    minOffset = -(subListWidth-listGroupRef.current.clientWidth);
  };

  return(
    <>
    <Nav className="justify-content-center project-nav" variant="pills" defaultActiveKey="#">
      <Nav.Item>
        <Nav.Link onClick={() => {changeProjects(-1)}} href="#" className="text-dark">ALL</Nav.Link>
      </Nav.Item>
      {Project_type.map((pt) => {
        return (
          <Nav.Item key={pt.id}>
            <Nav.Link onClick={() => {changeProjects(pt.id)}} className="text-dark" eventKey={"link-"+pt.id}>{pt.name}</Nav.Link>
          </Nav.Item>
        )
      })}
    </Nav>
    
    {/* sub nav */}
    <div className='slider-container my-4 d-flex'>
      {
        listGroupSize<subListWidth?
        <Button onClick={()=>{prevSlide()}} style={{opacity:"0.3", cursor:"default", pointerEvents:"none"}} ref={prevBtnRef}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </Button>:
        <></>
      }
          <RadioButtonGroup 
            className="justify-content-start subnav" 
            variant="pills" ref={listGroupRef}> 
            <div className="text-color-hex-4">자세히 보려면 클릭하세요 ↓</div>
          <RadioButtonContainer ref={listRef}
            width={`${thumbnailBtn_width*projects.length}px`}>
            {projects.map((project, i) => {
              return (
                <>
                <BtnCheck
                  type="radio"
                  id={`radio-${project.id}`}
                  autoComplete="off"
                  onClick={(e) => {
                    if(e.currentTarget.checked) e.currentTarget.checked=false;
                  }}
                  onChange={(e) => {
                    selectSubNav(project); 
                    setRadioValue(e.currentTarget.value);
                  }}
                  name='subnav'
                />
                <RadioToggleButton
                  tabIndex={i}
                  htmlFor={`radio-${project.id}`}
                  width={`${thumbnailBtn_width}px`}
                  project={project}
                  selectedProject={selectedProject}
                  checked={checked}
                  key={project.id}/>
                </>
              )
            })}
            </RadioButtonContainer>
          </RadioButtonGroup>
      {
        listGroupSize<subListWidth?
        <Button onClick={()=>{nextSlide()}} ref={nextBtnRef}>
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>:
        <></>
      }
    </div>

    <PDGroup ref={pdGroupRef}>
      <div className='project-detail' ref={projectDetailRef}>
        <img 
          src={selectedProject.image}
          alt="projectImage"
          className="w-100"
        />
        <h5 className="mt-3 fw-bold"><>{selectedProject.title}</></h5>
        <ProjectContents>{selectedProject.contents}</ProjectContents>
      </div>
    </PDGroup>
    </>
  )
}

function RadioToggleButton(props) {
  const [ref, hover] = useHover();


  return(
    <div style={{width:"auto", height:"180px", position:"relative"}}>
    <MyIconContainer width={icon_width} height={icon_height}>
      {hover||(props.selectedProject.id==props.project.id&&props.checked)?
      <>
        <MyIcon src={props.project.thumbnail_image} width={icon_width*1.1} height={icon_height*1.1} margin={-icon_height*0.05}/>
        <HoverBoxStyle>
          <div className='icon-text'>
            <h6>{props.project.title}</h6>
            {props.project.icon_text}
          </div>
        </HoverBoxStyle>
      </>:
      <MyIcon src={props.project.thumbnail_image} width={icon_width} height={icon_height}/>
      }
      </MyIconContainer>
      <RadioToggleButtonStyle
        tabIndex={props.tabIndex}
        htmlFor={props.htmlFor}
        width={props.width}
        ref={ref}
      >
        {props.project.title}
      </RadioToggleButtonStyle>
    </div>
  )
}
const PDGroup = styled.div`
  height:auto;
  overflow: hidden;
`
const HoverBoxStyle = styled.div`
position:absolute;
width:221px;
height:156px;
padding:20px;
left:0;
top:0;
`

const MyIconContainer = styled.div`
width:${props=>props.width}px;
height:${props=>props.height}px;
margin:10px;
overflow:hidden;
`

const MyIcon = styled.img`
position:relative;
width:${props=>props.width}px;
height:${props=>props.height}px;
margin:${props=>props.margin?props.margin:"0"}px;
transition:all ease-in 0.3s;
`
MyIcon.defaultProps ={
src:Projects[0].thumbnail_image,
};

let RadioButtonContainer = styled.div`
  display:flex;  
  flex-flow: row nowrap;
  width:${props=>props.width}
`

let BtnCheck = styled.input`
  position:absolute;
  clip: rect(0,0,0,0);
`

let RadioButtonGroup = styled.div`
width:100%;
  overflow:hidden;
`

let RadioToggleButtonStyle = styled.label`
  position:absolute;
  top:0;
  left:0;
  width:${props=>props.width};
  padding-top:156px;
  z-index:2;
  display:flex;
  flex-direction:column;
  cursor:pointer;
`

let ProjectContents = styled.div`
color:#777777;
font-size:15px;
`

const Button = styled.button`
  position:absolute;
  top:0;
  bottom:0;
  z-index:1;
  align-items:center;
  width:5%;
  all:unset;
  border: 1px solid #1B3558;
  padding:47px 15px;
  color:#5888cb;
  font-size:2em;
  &:hover {
    transition: all 0.3s ease-in-out;
    background-color:#1B3558;
    color:#fff;
  }
`;

export default Project;