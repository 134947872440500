import styled from "styled-components";
import React, {useState, useEffect, useRef} from "react";
import useInterval from "../hooks/useInterval.js";
import CardHeader from 'react-bootstrap/esm/CardHeader';
import {Card, card} from 'react-bootstrap';
// data
import Services from '../data/services';

export default function Slider() {

  const slideRef = useRef("");
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    slideRef.current.style.transition = "all 0.5s ease-in-out";
    slideRef.current.style.transform = `translateX(-${currentSlide}00vw)`;
  }, [currentSlide]);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //   }, 5000);
  //   return () => clearInterval(timer)
  // }, []);

  useInterval(() => {
    let nextSlideNum = currentSlide + 1;
    if(nextSlideNum>=3) nextSlideNum=0;
    setCurrentSlide(nextSlideNum);
  }, 5000)
  
  function SliderContent({data}) {
      return(
      <div className='vw-100 d-flex main-slider-justify align-items-center align-content-center container'>
        <div className="slider-caption">
          <div className="slider-text">
            <h2 className='slider-title'>{data.title}</h2>
            <div className="slider-content">{data.contents}</div>
          </div>
          <div> 
            <ol className="banner-nav-group d-flex mb-0">
                {Services.map((otherservice, j) =>{
                  return(
                    <li className="banner-nav" onClick={() => {setCurrentSlide(j)}} key={j}>
                      <Card>
                        <CardHeader>
                          <div className='slider-btn'>
                            <img src={otherservice.image} className='slider-btn-image'/>
                          </div>
                          <div className='plus-btn'>{otherservice.title}</div>
                        </CardHeader>
                        <div className="btn-more d-flex justify-content-center">
                          <div className="hidden-550">MORE</div>
                          <div className="hidden-550">+</div>
                        </div>
                      </Card>
                    </li>
                  )
                })}
              </ol>
          </div>
        </div>
        <div className="slider-image-wrapper">
          <img src={data.image} className='slider-image'/>
        </div>
      </div>
    );
  }


  return(
    <Container>
    <h2 className="mt-5 text-start container py-0 web-color-white"><b>아이코드랩과 함께라면 모든 것이 가능해집니다.</b></h2>
      <SliderContainer ref={slideRef}>
        {Services.map((service, i) => {
          return(
            <>
            <SliderContent data={service} key={service}/>   
            </>                    
          )
        })}
      </SliderContainer>
    </Container>
  );
}
const Container = styled.div`
  width:100%;
  overflow:hidden;
  background-color:#0A1322;
`;

const SliderContainer = styled.div`
  width:300vw;
  height:auto;
  display:flex;
`
